@use "../../theme/sass/get-theme";

.tutorial-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url("../../assets/background-image.svg");
  background-repeat: no-repeat;
  background-position: center 35%;
  //   flex: 1 0 auto;
  align-items: center;
  // gap: 1.5rem;
  .info-container {
    //max-height: 12rem;
    padding-top: 3rem;
    // padding-bottom: 2.5rem;
    // width: 100%;
    text-align: center;
  }

  .sample-snap-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    .sample-snap {
      width: calc(100% - 4rem);
      height: calc(100% - 3rem);
      padding: 2rem 1.5rem;
    }
  }

  .tutorial-message {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
