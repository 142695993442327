@use "../../theme/sass/get-theme";

.result-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url("../../assets/background-image.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  flex: 1 0 auto;
  align-items: center;
  // gap: 1.5rem;
  .info-container {
    //padding-top: 2rem;

    .info {
      text-align: center;
      @include get-theme.get-theme(color, text);
    }
  }

  .tyre-info {
    @include get-theme.get-theme(color, dark-text);
  }
  .option-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 100%;
    // flex-grow: 1;
    //margin-top: 2rem;
    align-items: center;
    overflow: auto;
  }
  .submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    //  padding: 2rem;
    width: 100%;
  }
  .bottom-text {
    padding: 2rem;
    text-align: center;
  }
}
