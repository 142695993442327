@use "sass:map";
// @use './_color-pallete.scss';

$themes: (
  default: (
    color-primary: #27509b,
    color-secondary: #333333,
    background-primary: white,
    background-secondary: #fce500,
    background-tertiary: #333333,
    background-default: #cccccc,
    text: #333333,
    dark-text: #1a1a1a,
    text-secondary: #7f7f7f,
    text-tertiary: #999999,
    border-primary: #27509b,
    card-background: #f2f2f2,
    card-background-selected: #eaeef5,
  ),
  theme1: (
    color-primary: #87cefa,
    color-secondary: white,
    background-primary: white,
    background-secondary: #87cefa,
    background-tertiary: #333333,
    background-default: #cccccc,
    text: #333333,
    text-secondary: #7f7f7f,
    border-primary: #87cefa,
    card-background: #f2f2f2,
    card-background-selected: #eaeef5,
    dark-text: #1a1a1a,
  ),
);

@mixin get-theme($property, $key, $inlineExtra: null, $postExtra: null) {
  @each $theme-name, $theme-set in $themes {
    // do handling here
    $value: map-get(
      $map: $theme-set,
      $key: $key,
    );
    .theme-#{$theme-name} & {
      #{$property}: #{$inlineExtra} #{$value} #{$postExtra};
    }
  }
}
