@use "../../theme/sass/get-theme";

.runflat-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../../assets/background-image.svg");
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  .info-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    text-align: center;
  }
  .submit-buttons-container {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
