@use "../../theme/sass/get-theme";

.result-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url("../../assets/background-image.svg");
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;

  .final-flag-snap {
    width: 7.87rem;
    height: 4.25rem;
  }
  .final-message {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
}
