@use "../../theme/sass/get-theme";

.option-card {
  border-radius: 0.6rem;
  padding: 1rem;
  width: 80%;

  .radio-container {
    margin-left: 2.5rem;
    display: flex;
    gap: 0.25rem;
    input[type="radio"] {
      display: none;
    }
    label {
      display: flex;
      width: 100%;
      gap: 0.5rem;
      align-items: center;
    }
    input[type="radio"] + label::before {
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background-image: url("../../assets/radioDefault.png");
      background-size: contain;
    }
    input[type="radio"]:checked + label::before {
      background-image: url("../../assets/radioSelected.png");
    }
  }

  .radio-label {
    @include get-theme.get-theme(color, color-primary);
  }
  .card-content {
    margin-left: 4rem;
  }
}
.default-background {
  @include get-theme.get-theme(background-color, card-background);
}
.selected-background {
  @include get-theme.get-theme(background-color, card-background-selected);
  @include get-theme.get-theme(border, border-primary, 1px solid);
}
