@use "../../theme/sass/get-theme";
.home-container {
  height: 100%;
}
.home-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url("../../assets/background-image.svg");
  background-repeat: no-repeat;
  background-position: center;
  flex: 1 0 auto;
  align-items: center;
  // gap: 1.5rem;
  .info-container {
    padding-top: 2rem;

    .info {
      padding-left: 3rem;
      padding-right: 3rem;
      text-align: center;
      @include get-theme.get-theme(color, text);
    }
  }

  .sample-snap-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    .sample-snap {
      width: calc(100% - 4rem);
      height: calc(100% - 3rem);
      padding: 2rem 1.5rem;
    }
  }

  .choose-from-gallery-btn {
    width: 19.5rem;
    height: 45px;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    input[type="file"] {
      display: none;
    }
  }
}
