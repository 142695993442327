@use "../../theme/sass/get-theme";
$border: 0.75rem;

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  @include get-theme.get-theme(background-color, background-primary);
}

.spinner {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  @include get-theme.get-theme(border, text-secondary, 0.3rem solid);
  @include get-theme.get-theme(border-top, color-primary, 0.3rem solid);
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.show-spinner {
  display: flex;
}

.hide-spinner {
  display: none;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
