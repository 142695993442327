@use "../../theme/sass/get-theme";

.view-image-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}

.display-img {
  height: auto;
  width: 100%;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
  @include get-theme.get-theme(background-color, background-primary);
}

.underline-text {
  @include get-theme.get-theme(color, color-primary);
  @include get-theme.get-theme(border-bottom, color-primary, 1px solid);
}
