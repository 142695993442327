@use "../../theme/sass/get-theme";

.tyreOption-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url("../../assets/background-image.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  flex: 1 0 auto;
  align-items: center;

  .info-container {
    padding-top: 2rem;
    text-align: center;
    @include get-theme.get-theme(color, text);
  }

  .sample-snap-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    .sample-snap {
      width: calc(100% - 4rem);
      height: calc(100% - 3rem);
      padding: 2rem 1.5rem;
    }
  }

  .option-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 100%;
    // flex-grow: 1;
    //margin-top: 2rem;
    align-items: center;
  }
  .button-div {
    padding-bottom: 2rem;
  }
}
