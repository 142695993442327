@use "../../theme/sass/get-theme";

.error-screen-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url("../../assets/background-image.svg");
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
}
.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
}
