@use "./theme/sass/get-theme";

.app {
  @include get-theme.get-theme(background-color, background-primary);
  height: 100vh;
  width: 100vw;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  vertical-align: baseline;
}

* {
  font-family: "Noto Sans", sans-serif;
}

button {
  border-radius: 25px;
  min-width: 2.5rem;
  width: 19.5rem;
  height: 50px;
}

.icon-button {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.back-arrow {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 25px;
  top: 10px;
}
.icon-size {
  height: 1.2rem;
  width: 1.2rem;
}
.secondary-button {
  border: none;
  @include get-theme.get-theme(background-color, background-secondary);
  @include get-theme.get-theme(border, border-primary);
  @include get-theme.get-theme(color, text);
}
.default-button {
  border: none;
  @include get-theme.get-theme(background-color, background-default);
  @include get-theme.get-theme(color, text-secondary);
}

.primary-button {
  @include get-theme.get-theme(background-color, background-primary);
  @include get-theme.get-theme(border, border-primary, 2px, solid);
  @include get-theme.get-theme(color, color-primary);
}
.text-color {
  @include get-theme.get-theme(color, text);
}
.text-color-secondary {
  @include get-theme.get-theme(color, text-secondary);
}
.h1 {
  font-size: 20px;
}
.h2 {
  font-size: 18px;
}
.h3 {
  font-size: 16px;
}
.h4 {
  font-size: 14px;
}
.h5 {
  font-size: 12px;
}
.h6 {
  font-size: 10px;
}
.italian {
  font-style: italic;
  font-weight: 400;
}
.italian-bold {
  font-style: italic;
  font-weight: 900;
  font-size: 23px;
}
.bold {
  font-weight: bold;
}
